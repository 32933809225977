import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((t: Theme) => ({
  paper: {
    marginTop: t.spacing(-8),
    padding: t.spacing(4),
  },
  tabs: {
    marginBottom: t.spacing(4),
  },
  button: {
    marginTop: t.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainContent: {
    [t.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  result: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  solutionContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    border: 'dashed 4px',
    borderColor: t.palette.grey[400],
    borderRadius: t.spacing(1),
    height: '580px',
    marginBottom: t.spacing(1),
    [t.breakpoints.down('sm')]: {
      height: '300px',
    },
  },
  errorText: {
    marginTop: t.spacing(2),
    color: t.palette.error.main,
    fontWeight: 700,
  },
  hidden: {
    visibility: 'hidden',
  },
  image: {
    cursor: 'inherit',
    position: 'relative',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    transition: 'all 200ms ease-out',
  },
  spinner: {
    position: 'absolute',
  },
  loadingImage: {
    filter: 'grayscale(100%) blur(2px)',
  },
  dragAndDropText: {
    position: 'absolute',
    fontStyle: 'italic',
    padding: '1em',
    background: '#fffc',
    borderRadius: t.spacing(0.5),
    [t.breakpoints.up('lg')]: {
      margin: 'auto',
    },
  },
  dropzone: {
    background: '#ffffff00',
    cursor: 'copy',
    color: 'black',
    transition: 'all 200ms ease-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    border: 'dashed 4px',
    borderColor: t.palette.grey[400],
    borderRadius: t.spacing(1),
    height: '580px',
    marginBottom: t.spacing(1),
    [t.breakpoints.down('sm')]: {
      height: '300px',
    },
    '&:hover': {
      background: '#ffffff66',
      '& img': {
        filter: 'grayscale(100%) blur(2px)',
      },
    },
  },
  preview: {
    width: '100%',
    height: '100%',
    position: 'relative',
    objectFit: 'contain',
    transition: 'all 200ms ease-out',
  },
  previewActive: {
    filter: 'grayscale(100%) blur(2px)',
  },
}))

export default useStyles
