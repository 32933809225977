import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import React, { ReactElement } from 'react'

const useStyles = makeStyles((t: Theme) => ({
  link: {
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    color: t.palette.primary.main,
    '&:hover': {
      color: t.palette.primary.light,
      cursor: 'pointer',
    },
    '&:focus': {
      color: t.palette.primary.light,
      cursor: 'pointer',
    },
    '&:active': {
      color: t.palette.primary.light,
      cursor: 'pointer',
    },
  },
}))

const VinManual = (): ReactElement => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      <Typography variant="body1" gutterBottom>
        VIN Reader (VR) by Cognexa is a high precision{' '}
        <a
          className={classes.link}
          href="https://en.wikipedia.org/wiki/Optical_character_recognition"
          target="_blank"
          rel="noopener noreferrer"
        >
          OCR
        </a>{' '}
        system designed for reading VIN codes. Just get a picture of the VIN code you need to read the value from, pass
        it to VIN Reader Demo and get the information back easy and quick with no manual input and no space for error.
      </Typography>
      <Typography variant="body1" gutterBottom>
        To use VIN Reader Demo app, just follow these simple steps:
      </Typography>
      <Typography variant="body1" component="ol" gutterBottom>
        <li>Get a clear picture of the VIN code according to the instructions below.</li>
        <li>Upload it here.</li>
        <li>Wait for the results of the recognition process.</li>
        <li>Check the extracted value.</li>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Instructions for taking photo:
      </Typography>
      <Typography variant="body1" gutterBottom>
        When generating VIN images, position the camera in a way that <b>eliminates glare</b> in the shot and take a
        <b>straight-on photo</b> of the VIN number. This way you will ensure the highest possible accuracy of VIN Reader
        and reliable value data extraction. Please, see the examples below the uploading container on the left.
      </Typography>
      <Typography variant="body1" gutterBottom>
        To learn more about the OdoCap system, please proceed to{' '}
        <a
          className={classes.link}
          href="https://www.cognexa.com/solutions/machine-vision/ocr-character-recognition-for-the-real-world/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cognexa
        </a>{' '}
        webpage.
      </Typography>
      <Typography variant="body2" gutterBottom>
        <WarningIcon color="error" fontSize="small" /> Please make sure there is just the VIN number on the photo you
        uploaded, not the whole VIN plate.
      </Typography>
    </>
  )
}

export default VinManual
