import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { useDrawResult } from '../../../helpers/useDrawResult'
import { Result, Size } from '../../../types'

interface Props {
  result: Result | null
  imageSize: Size
}

const useStyles = makeStyles(() => ({
  solutionCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    margin: 'auto',
  },
}))

const ImageCanvas = (props: Props): ReactElement => {
  const { result, imageSize } = props

  const theme = useTheme()
  const classes = useStyles(theme)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [canvasSize, setCanvasSize] = useState<Size>({ width: 0, height: 0 })

  const CanvasResizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const dimensions = entry.contentRect
      entry.target.setAttribute('height', dimensions.height.toString())
      entry.target.setAttribute('width', dimensions.width.toString())
      setCanvasSize({ width: dimensions.width, height: dimensions.height })
    })
  })

  const setUpCanvas = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current
      canvas.setAttribute('height', canvas.clientHeight.toString())
      canvas.setAttribute('width', canvas.clientWidth.toString())
    }
  }

  useEffect(() => {
    if (canvasRef.current) {
      const ref = canvasRef.current
      CanvasResizeObserver.observe(ref)
      return () => {
        if (ref) {
          CanvasResizeObserver.unobserve(ref)
        }
      }
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef])

  useEffect(() => {
    setUpCanvas()
  }, [])

  useDrawResult(result, canvasRef, imageSize, canvasSize)

  return (
    <canvas className={classes.solutionCanvas} ref={canvasRef} width={canvasSize.width} height={canvasSize.height} />
  )
}

export default ImageCanvas
