import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import React, { ReactElement } from 'react'

const useStyles = makeStyles((t: Theme) => ({
  link: {
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    color: t.palette.primary.main,
    '&:hover': {
      color: t.palette.primary.light,
      cursor: 'pointer',
    },
    '&:focus': {
      color: t.palette.primary.light,
      cursor: 'pointer',
    },
    '&:active': {
      color: t.palette.primary.light,
      cursor: 'pointer',
    },
  },
  linkContainer: {
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  header: {
    marginBottom: '1rem',
  },
}))

const LicencePlateManual = (): ReactElement => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      <Typography variant="h4" gutterBottom className={classes.header}>
        Try it!
      </Typography>
      <Typography variant="body1" gutterBottom>
        To use Licence Plate Demo just follow these instructions:
      </Typography>
      <Typography variant="body1" component="ol" gutterBottom>
        <li>Get a clear, straight-on photo of the car with a visible licence plate.</li>
        <li>Make sure the picture does not contain glare, light reflections and that it is not rotated.</li>
        <li>Upload a photo (or take it directly from your smartphone).</li>
        <li>Wait for the results of the recognition process.</li>
      </Typography>
      <Typography variant="h5" gutterBottom className={classes.linkContainer}>
        <a
          className={classes.link}
          href="https://www.cognexa.com/solutions/machine-vision/ocr-character-recognition-for-the-real-world/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more about our solutions!
        </a>
      </Typography>
      <Typography variant="body2" gutterBottom>
        <WarningIcon color="error" fontSize="small" /> This is just a demo version trained on the most used car models
        from Central Europe. We can easily accommodate new car models from your country to achieve an even higher
        confidence score.
      </Typography>
    </>
  )
}

export default LicencePlateManual
