import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import img1 from '../../../images/plates/47SXGX.jpg'
import img2 from '../../../images/plates/BA028AA.jpg'
import img3 from '../../../images/plates/BA999ZZ.jpg'
import img4 from '../../../images/plates/BL190MC.jpeg'
import img5 from '../../../images/plates/PD262DP.jpg'
import { GlobalState } from '../../../store/configureStore'

interface Props {
  selectFile: (event: React.MouseEvent) => void
}

const useStyles = makeStyles(() => ({
  smallImage: {
    width: '120px',
  },
  bigImage: {
    width: '180px',
  },
  disabledImg: {
    filter: 'grayscale(100%)',
    '&:hover': {
      cursor: 'default',
      transform: 'none',
      boxShadow: 'none',
    },
  },
}))

const LicencePlateGallery = (props: Props): ReactElement => {
  const { selectFile } = props
  const theme = useTheme()
  const classes = useStyles(theme)

  const isSubmitingImage = useSelector((state: GlobalState) => state.application.isSubmitingImage)

  return (
    <>
      {[img1, img2, img3, img4, img5].map((src) => (
        <img
          key={src}
          className={classNames(classes.smallImage, isSubmitingImage ? classes.disabledImg : '')}
          src={src}
          alt="licence plate"
          role="presentation"
          onClick={selectFile}
        />
      ))}
    </>
  )
}

export default LicencePlateGallery
