/* eslint-disable camelcase */
export type FileWithPreview = File & { preview: string }

export interface Result {
  coordinates: [number, number, number, number] | null
  value: number | string | null
  confidence: number | null
  // eslint-disable-next-line camelcase
  execution_time: number
}

export interface Size {
  width: number
  height: number
}

export enum jobTypes {
  odometer = 'odometer',
  licence_plate = 'licence_plate',
  vin = 'vin',
}

export enum jobTexts {
  odometer = 'Odometer',
  licence_plate = 'Licence plate',
  vin = 'VIN',
}

export interface UserInfo {
  name: string
  email: string
  company: string
}

export interface InputData {
  userInfo: UserInfo
}
