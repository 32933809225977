import { makeStyles, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import img1 from '../../../images/vin/4T1BF1FK7EU379242.jpg'
import img2 from '../../../images/vin/JTDBE30K500297215.jpeg'
// import img3 from '../../../images/vin/WDB1680332J640000.jpg'
import img4 from '../../../images/vin/WDCYC7CF8DX201851.jpg'
import { GlobalState } from '../../../store/configureStore'

interface Props {
  selectFile: (event: React.MouseEvent) => void
}

const useStyles = makeStyles(() => ({
  smallImage: {
    width: '120px',
  },
  bigImage: {
    width: '180px',
  },
  disabledImg: {
    filter: 'grayscale(100%)',
    '&:hover': {
      cursor: 'default',
      transform: 'none',
      boxShadow: 'none',
    },
  },
}))

const VinGallery = (props: Props): ReactElement => {
  const { selectFile } = props
  const theme = useTheme()
  const classes = useStyles(theme)

  const isSubmitingImage = useSelector((state: GlobalState) => state.application.isSubmitingImage)

  return (
    <>
      {[img1, img2, /* img3, */ img4].map((src) => (
        <img
          key={src}
          className={classNames(classes.bigImage, isSubmitingImage ? classes.disabledImg : '')}
          src={src}
          alt="vin"
          role="presentation"
          onClick={selectFile}
        />
      ))}
    </>
  )
}

export default VinGallery
