/* eslint-disable react/jsx-props-no-spreading */
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React, { ReactElement, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'

import { createFileFromImgSrc } from '../../helpers/createFileFromImgSrc'
import { useFileSelector } from '../../helpers/useFileSelector'
import { loadResponse, selectTab, setError, submitImage, tryAnotherImage } from '../../store/application/actions'
import { GlobalState } from '../../store/configureStore'
import { InputData, jobTexts, jobTypes } from '../../types'
import Gallery from './Gallery/Gallery'
import ImageCanvas from './ImageCanvas/ImageCanvas'
import TextContainer from './TextContainer/TextContainer'
import UserDetailsDialog from './UserDetailsDialog/UserDetailsDialog'
import useStyles from './useStyles'

const Main = (): ReactElement => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles(theme)

  const [cookies, setCookie, removeCookie] = useCookies()

  const { resetFile, file, naturalSize, getRootProps, getInputProps, isDragActive } = useFileSelector()

  const [selectedFile, setSelectedFile] = useState<Blob | null>(null)
  const [selectedFileAddress, setSelectedFileAddress] = useState('')
  const [naturalSizeOfselectedFile, setNaturalSizeOfselectedFile] = useState({ width: 0, height: 0 })
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const isSubmitingImage = useSelector((state: GlobalState) => state.application.isSubmitingImage)
  const id = useSelector((state: GlobalState) => state.application.id)
  const error = useSelector((state: GlobalState) => state.application.error)
  const result = useSelector((state: GlobalState) => state.application.result)
  const isWaitingForResponse = useSelector((state: GlobalState) => state.application.isWaitingForResponse)
  const isLoadingResponse = useSelector((state: GlobalState) => state.application.isLoadingResponse)
  const tab = useSelector((state: GlobalState) => state.application.tab)

  const tryAgain = () => {
    dispatch(tryAnotherImage())
    resetFile()
    setSelectedFile(null)
  }

  const handleChange = (_e: React.ChangeEvent<unknown>, newValue: string) => {
    tryAgain()
    dispatch(selectTab(newValue))
  }

  const selectFile = async (event: React.MouseEvent) => {
    if (!isSubmitingImage) {
      const target = event.target as HTMLImageElement
      const newImageBlob = await createFileFromImgSrc(target.src)
      setSelectedFileAddress(target.src)
      setSelectedFile(newImageBlob)
      setNaturalSizeOfselectedFile({ width: target.naturalWidth, height: target.naturalHeight })
    }
  }

  const trySubmitImage = (inputData: InputData) => {
    if (file) {
      dispatch(submitImage(file, tab, inputData))
    } else {
      dispatch(setError('No image was selected'))
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (cookies.userInfo) {
      trySubmitImage({ userInfo: cookies.userInfo })
    } else {
      setOpenDialog(true)
    }
  }

  useEffect(() => {
    if (selectedFile) {
      dispatch(submitImage(selectedFile, tab))
    }
  }, [dispatch, selectedFile, tab])

  useEffect(() => {
    if (id && !isLoadingResponse && result === null) {
      const interval = setInterval(() => {
        if (id) {
          dispatch(loadResponse(id))
        }
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }
    return () => {}
  }, [id, isLoadingResponse, dispatch, result])

  return (
    <Container maxWidth="xl" component="main">
      <Paper className={classes.paper}>
        <Container maxWidth="lg" component="section">
          <UserDetailsDialog
            open={openDialog}
            handleClose={() => setOpenDialog(false)}
            handleConfirm={trySubmitImage}
          />
          <Grid item xs={12} className={classes.tabs}>
            <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
              <Tab label={jobTexts.odometer} value={jobTypes.odometer} />
              <Tab label={jobTexts.licence_plate} value={jobTypes.licence_plate} />
              {
                // <Tab label={jobTexts.vin} value={jobTypes.vin} />
              }
            </Tabs>
          </Grid>
          <Grid container spacing={4} className={classes.mainContent}>
            <Grid item container md={8} sm={12}>
              {id ? (
                <Grid item container xs={12}>
                  <Grid item xs={12} className={classes.solutionContainer}>
                    <img
                      className={classNames(classes.image, isWaitingForResponse ? classes.loadingImage : '')}
                      src={selectedFile ? selectedFileAddress : file?.preview}
                      alt="Submitted"
                    />
                    {isWaitingForResponse && <CircularProgress size={80} className={classes.spinner} />}
                    {false && (
                      <ImageCanvas result={result} imageSize={selectedFile ? naturalSizeOfselectedFile : naturalSize} />
                    )}
                  </Grid>
                  {!isWaitingForResponse && result && (
                    <>
                      <Grid item xs={12} className={classes.result}>
                        <Typography variant="h5" gutterBottom>
                          Results:
                        </Typography>
                        <Typography variant="subtitle1">Read value: {result.value}</Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Confidence: {(result.confidence ? result.confidence * 100 : 0).toFixed(2)}%
                        </Typography>
                        {result.value === null && (
                          <Typography variant="subtitle1" gutterBottom>
                            Sorry, Odocap did not find any value. Please try another photo.
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} className={classes.button}>
                        <Button variant="contained" color="primary" type="button" onClick={tryAgain}>
                          Try another image
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <>
                  <Grid item container xs={12} component="form" onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                      <section {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        {file && (
                          <img
                            src={file.preview}
                            className={classNames(classes.preview, isDragActive ? classes.previewActive : '')}
                            alt="Submitted"
                          />
                        )}
                        <Typography variant="subtitle1" className={classes.dragAndDropText}>
                          Click to add an image from your device
                        </Typography>
                      </section>
                    </Grid>
                    <Grid item xs={12} className={classes.button}>
                      <Button
                        variant="contained"
                        className={classNames(!file || isSubmitingImage ? classes.hidden : '')}
                        color="primary"
                        type="submit"
                        disabled={isSubmitingImage || !file}
                      >
                        Submit image
                      </Button>
                      {error !== '' && (
                        <Typography variant="subtitle1" gutterBottom className={classes.errorText}>
                          {error}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Gallery selectFile={selectFile} />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item md={4} sm={12} component="article">
              <TextContainer />
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  )
}

export default Main
