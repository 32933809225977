import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../store/configureStore'
import { jobTypes } from '../../../types'
import LicencePlateManual from './LicencePlateManual'
import OdometerManual from './OdometerManual'
import VinManual from './VinManual'

const TextContainer = (): ReactElement => {
  const tab = useSelector((state: GlobalState) => state.application.tab)

  switch (tab) {
    case jobTypes.odometer:
      return <OdometerManual />
    case jobTypes.licence_plate:
      return <LicencePlateManual />
    case jobTypes.vin:
      return <VinManual />
    default:
      return <div />
  }
}

export default TextContainer
