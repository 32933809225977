import { jobTypes } from '../../types'
import {
  ApplicationState,
  ApplicationTypes,
  LOAD_RESPONSE_ERRORED,
  LOAD_RESPONSE_FINISHED,
  LOAD_RESPONSE_STARTED,
  SELECT_TAB,
  SET_ERROR,
  SUBMIT_IMAGE_ERRORED,
  SUBMIT_IMAGE_FINISHED,
  SUBMIT_IMAGE_STARTED,
  TRY_ANOTHER_IMAGE,
} from './types'

export const initialState: ApplicationState = {
  id: null,
  isLoadingResponse: false,
  isWaitingForResponse: false,
  isSubmitingImage: false,
  error: '',
  result: null,
  tab: jobTypes.odometer,
}

const applicationReducer = (state = initialState, action: ApplicationTypes): ApplicationState => {
  switch (action.type) {
    case SUBMIT_IMAGE_STARTED:
      return {
        ...state,
        error: '',
        isSubmitingImage: true,
      }
    case SUBMIT_IMAGE_FINISHED:
      return {
        ...state,
        id: action.payload.id,
        isWaitingForResponse: true,
        isSubmitingImage: false,
      }
    case SUBMIT_IMAGE_ERRORED:
      return {
        ...state,
        error: action.payload.error,
        isSubmitingImage: false,
      }
    case LOAD_RESPONSE_STARTED:
      return {
        ...state,
        isLoadingResponse: true,
      }
    case LOAD_RESPONSE_FINISHED:
      return {
        ...state,
        result: action.payload.result,
        isWaitingForResponse: action.payload.result === null,
        isLoadingResponse: false,
      }
    case LOAD_RESPONSE_ERRORED:
      return {
        ...state,
        error: action.payload.error,
        id: null,
        isLoadingResponse: false,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      }
    case TRY_ANOTHER_IMAGE:
      return {
        ...state,
        id: null,
        result: null,
        error: '',
      }
    case SELECT_TAB:
      return {
        ...state,
        tab: action.payload.tab,
      }
    default:
      return state
  }
}

export default applicationReducer
