import { Checkbox, FormControlLabel, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Link from '@material-ui/core/Link'
import Slide from '@material-ui/core/Slide'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@material-ui/core/transitions'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

import { InputData } from '../../../types'

const Transition = React.forwardRef(
  (
    // eslint-disable-next-line react/require-default-props
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Slide direction="up" ref={ref} {...props} />
  },
)

interface Props {
  open: boolean
  handleClose: () => void
  handleConfirm: (inputData: InputData) => void
}

const useStyles = makeStyles((t: Theme) => ({
  consent: {
    marginTop: t.spacing(2),
  },
}))

const UserDetailsDialog = (props: Props) => {
  const { handleClose, handleConfirm, open } = props

  const theme = useTheme()
  const classes = useStyles(theme)
  const [cookies, setCookie, removeCookie] = useCookies()

  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [company, setCompany] = useState<string>('')
  const [consent, setConsent] = useState<boolean>(false)

  const handleSubmit = (e: React.FormEvent, callback: (inputData: InputData) => void) => {
    e.preventDefault()
    setCookie('userInfo', { name, email, company })
    setName('')
    setEmail('')
    setCompany('')
    setConsent(false)
    handleClose()
    callback({ userInfo: { name, email, company } })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      maxWidth="xs"
    >
      <form onSubmit={(event) => handleSubmit(event, handleConfirm)}>
        <DialogContent>
          <DialogContentText>Please, provide your contact details:</DialogContentText>
          <TextField
            id="name"
            label="Your name"
            type="text"
            autoFocus
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="email"
            label="Email"
            type="email"
            margin="normal"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            id="company"
            label="Company"
            type="text"
            margin="normal"
            fullWidth
            required
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={consent}
                required
                onChange={() => setConsent(!consent)}
                name="consent"
                color="secondary"
              />
            }
            label={
              <Typography>
                I agree with{' '}
                <Link href="https://www.cognexa.com/privacy-policy/" target="_blank" color="secondary">
                  Privacy Policy
                </Link>
                .
              </Typography>
            }
            className={classes.consent}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Submit image
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default UserDetailsDialog
