import { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { FileWithPreview } from '../types'

// eslint-disable-next-line import/prefer-default-export
export const useFileSelector = () => {
  const [naturalSize, setNaturalSize] = useState({ width: 0, height: 0 })
  const [file, setFile] = useState<FileWithPreview | null>(null)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      const reader = new FileReader()

      reader.onerror = () => {
        reader.abort()
      }

      reader.onload = () => {
        const image = new Image()
        image.onload = () => {
          setNaturalSize({ width: image.naturalWidth, height: image.naturalHeight })
        }
        image.src = reader.result as string
      }
      const uploadedImage = acceptedFiles[0]
      reader.readAsDataURL(uploadedImage)
      setFile(
        Object.assign(uploadedImage, {
          preview: URL.createObjectURL(uploadedImage),
        }),
      )
    },
  })

  const resetFile = () => {
    setFile(null)
    setNaturalSize({ width: 0, height: 0 })
  }

  return { resetFile, file, naturalSize, getRootProps, getInputProps, isDragActive }
}
