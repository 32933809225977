import Container from '@material-ui/core/Container'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'

import logo from '../../images/odocap_logo_placeholder.png'

const useStyles = makeStyles((t: Theme) => ({
  mainHeader: {
    background: "url('background.jpg')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    height: '80vh',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [t.breakpoints.down('md')]: {
      height: '60vh',
    },
  },
  mainHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    '& img': {
      marginRight: t.spacing(2),
      maxWidth: '60px',
    },
    '& h1': {
      marginTop: t.spacing(4),
      color: t.palette.common.white,
    },
  },
  mainHeaderText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    color: t.palette.common.white,
    '& p': {
      fontSize: t.spacing(2.5),
    },
    '& ul': {
      fontSize: t.spacing(2.5),
    },
  },
  link: {
    background: 'none',
    border: 'none',
    fontWeight: 'bold',
    color: t.palette.primary.light,
    '&:hover': {
      color: t.palette.secondary.light,
      cursor: 'pointer',
    },
    '&:focus': {
      color: t.palette.secondary.light,
      cursor: 'pointer',
    },
    '&:active': {
      color: t.palette.secondary.light,
      cursor: 'pointer',
    },
  },
  center: {
    textAlign: 'center',
  },
}))

const Header = (): ReactElement => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <header className={classes.mainHeader}>
      <Container maxWidth="md" className={classes.mainHeaderContainer}>
        <img src={logo} alt="Odocap logo" />
        <Typography variant="h3" color="inherit" noWrap component="h1" gutterBottom>
          OdoCap Demo
        </Typography>
      </Container>
      <Container maxWidth="md" className={classes.mainHeaderText}>
        <Typography variant="body1" color="inherit" gutterBottom className={classes.center}>
          OdoCap by Cognexa is a high precision Optical Character Recognition (
          <a
            className={classes.link}
            href="https://en.wikipedia.org/wiki/Optical_character_recognition"
            target="_blank"
            rel="noopener noreferrer"
          >
            OCR
          </a>
          ) system designed for reading mileage from car dashboard and reading licence plate value from car photo.
        </Typography>
        <Typography variant="body1" color="inherit" gutterBottom className={classes.center}>
          <a
            className={classes.link}
            href="https://www.cognexa.com/use-cases/car-insurance-and-fleet-management/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more!
          </a>
        </Typography>
      </Container>
    </header>
  )
}

export default Header
