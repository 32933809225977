import { makeStyles, ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import React, { ReactElement } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'

import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Main from './components/Main/Main'
import { theme } from './helpers/theme'
import { configureStore } from './store/configureStore'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}))

const App = (): ReactElement => {
  const store = configureStore()
  const classes = useStyles(theme)

  return (
    <Provider store={store}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className={classes.root}>
            <Header />
            <Main />
            <Footer />
          </div>
        </ThemeProvider>
      </CookiesProvider>
    </Provider>
  )
}

export default App
