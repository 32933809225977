import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { GlobalState } from '../../../store/configureStore'
import { jobTypes } from '../../../types'
import LicencePlateGallery from './LicencePlateGallery'
import OdometerGallery from './OdometerGallery'
import VinGallery from './VinGallery'

interface Props {
  selectFile: (event: React.MouseEvent) => void
}

const useStyles = makeStyles((t: Theme) => ({
  galleryText: {
    margin: t.spacing(1),
    textAlign: 'center',
    fontStyle: 'italic',
  },
  gallery: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& img': {
      transition: 'all 0.2s ease-out',
      margin: t.spacing(1),
      '&:hover': {
        cursor: 'pointer',
        transform: 'scale(1.1)',
        boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.75)',
      },
    },
  },
}))

const Gallery = (props: Props): ReactElement => {
  const { selectFile } = props
  const theme = useTheme()
  const classes = useStyles(theme)

  const tab = useSelector((state: GlobalState) => state.application.tab)

  let gallery = null
  switch (tab) {
    case jobTypes.odometer:
      gallery = <OdometerGallery selectFile={selectFile} />
      break
    case jobTypes.vin:
      gallery = <VinGallery selectFile={selectFile} />
      break
    case jobTypes.licence_plate:
      gallery = <LicencePlateGallery selectFile={selectFile} />
      break
    default:
      gallery = <div />
      break
  }

  return (
    <>
      <Typography variant="subtitle1" gutterBottom className={classes.galleryText}>
        or click on one of our images to use it
      </Typography>
      <div className={classes.gallery}>{gallery}</div>
    </>
  )
}

export default Gallery
