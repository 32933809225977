import { createMuiTheme } from '@material-ui/core'

// eslint-disable-next-line import/prefer-default-export
export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#41B6E6',
      main: '#13294b',
      dark: '#41B6E6',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#ff9f2a',
      dark: '#ff9f2a',
      contrastText: '#fff',
    },
  },
})
