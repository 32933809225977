import Axios from 'axios'
import { format } from 'date-fns'
import { ThunkDispatch } from 'redux-thunk'

import { FileWithPreview, InputData } from '../../types'
import {
  ApplicationState,
  ApplicationTypes,
  LOAD_RESPONSE_ERRORED,
  LOAD_RESPONSE_FINISHED,
  LOAD_RESPONSE_STARTED,
  SELECT_TAB,
  SET_ERROR,
  SUBMIT_IMAGE_ERRORED,
  SUBMIT_IMAGE_FINISHED,
  SUBMIT_IMAGE_STARTED,
  TRY_ANOTHER_IMAGE,
} from './types'

const submitImage = (image: FileWithPreview | Blob, type: string, inputData?: InputData) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SUBMIT_IMAGE_STARTED,
    })
    try {
      const formData = new FormData()
      formData.append(`file`, image, 'odometer.jpg')
      formData.append(`type`, type)
      formData.append(`input_data`, JSON.stringify(inputData || {}))

      const submitImageRequest = await Axios.post('/api/request', formData)
      dispatch({
        type: SUBMIT_IMAGE_FINISHED,
        payload: {
          id: submitImageRequest.data.id,
        },
      })
    } catch (e) {
      const ratelimit = e.response.headers['x-ratelimit-reset']
      dispatch({
        type: SUBMIT_IMAGE_ERRORED,
        payload: {
          error: `${e.response.data.error} ${
            ratelimit === '0' ? `Another will be possible at ${format(new Date(ratelimit * 1000), 'HH:mm:ss')}.` : ''
          }`,
        },
      })
    }
  }
}

const loadResponse = (id: string) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: LOAD_RESPONSE_STARTED,
    })
    try {
      const loadResponseRequest = await Axios.get(`/api/response/${id}?_=${+new Date()}`)
      dispatch({
        type: LOAD_RESPONSE_FINISHED,
        payload: {
          result: loadResponseRequest.data.message !== 'No data yet' ? loadResponseRequest.data.valueObject : null,
        },
      })
    } catch (e) {
      dispatch({
        type: LOAD_RESPONSE_ERRORED,
        payload: {
          error: e.response.data.error,
        },
      })
    }
  }
}

const tryAnotherImage = () => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: TRY_ANOTHER_IMAGE,
    })
  }
}

const setError = (error: string) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SET_ERROR,
      payload: {
        error,
      },
    })
  }
}

const selectTab = (tab: string) => {
  return async (dispatch: ThunkDispatch<ApplicationState, null, ApplicationTypes>) => {
    dispatch({
      type: SELECT_TAB,
      payload: {
        tab,
      },
    })
  }
}

export { submitImage, loadResponse, tryAnotherImage, setError, selectTab }
