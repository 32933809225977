import { Result } from '../../types'

export interface ApplicationState {
  id: string | null
  isLoadingResponse: boolean
  isWaitingForResponse: boolean
  isSubmitingImage: boolean
  error: string
  result: Result | null
  tab: string
}

export const TRY_ANOTHER_IMAGE = 'TRY_ANOTHER_IMAGE'
export const SET_ERROR = 'SET_ERROR'
export const SUBMIT_IMAGE_STARTED = 'SUBMIT_IMAGE_STARTED'
export const SUBMIT_IMAGE_FINISHED = 'SUBMIT_IMAGE_FINISHED'
export const SUBMIT_IMAGE_ERRORED = 'SUBMIT_IMAGE_ERRORED'
export const LOAD_RESPONSE_STARTED = 'LOAD_RESPONSE_STARTED'
export const LOAD_RESPONSE_FINISHED = 'LOAD_RESPONSE_FINISHED'
export const LOAD_RESPONSE_ERRORED = 'LOAD_RESPONSE_ERRORED'
export const SELECT_TAB = 'SELECT_TAB'

interface TryAnotherImage {
  type: typeof TRY_ANOTHER_IMAGE
}

interface SetError {
  type: typeof SET_ERROR
  payload: {
    error: string
  }
}

interface SubmitImageStarted {
  type: typeof SUBMIT_IMAGE_STARTED
}

interface SubmitImageErrored {
  type: typeof SUBMIT_IMAGE_ERRORED
  payload: {
    error: string
  }
}

interface SubmitImageFinished {
  type: typeof SUBMIT_IMAGE_FINISHED
  payload: {
    id: string
  }
}

interface LoadResponseStarted {
  type: typeof LOAD_RESPONSE_STARTED
}

interface LoadResponseErrored {
  type: typeof LOAD_RESPONSE_ERRORED
  payload: {
    error: string
  }
}

interface LoadResponseFinished {
  type: typeof LOAD_RESPONSE_FINISHED
  payload: {
    result: Result | null
  }
}

interface SelectTab {
  type: typeof SELECT_TAB
  payload: {
    tab: string
  }
}

export type ApplicationTypes =
  | TryAnotherImage
  | SetError
  | SubmitImageStarted
  | SubmitImageErrored
  | SubmitImageFinished
  | LoadResponseStarted
  | LoadResponseErrored
  | LoadResponseFinished
  | SelectTab
