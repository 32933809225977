import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { ReactElement } from 'react'

import logo from '../../images/logo.png'

const useStyles = makeStyles((t: Theme) => ({
  logo: {
    width: '100px',
    marginTop: t.spacing(0.5),
    marginLeft: t.spacing(0.25),
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    padding: t.spacing(3, 2),
  },
}))

const Footer = (): ReactElement => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const years = new Date().getFullYear() === 2020 ? '2020' : `2020 - ${new Date().getFullYear()}`

  return (
    <Container className={classes.footer} maxWidth="md" component="footer">
      <Typography variant="body2" color="textSecondary" align="center">
        {`Copyright © ${years}`}
        &nbsp;|&nbsp;
      </Typography>
      <Link color="inherit" href="https://cognexa.com">
        <img src={logo} alt="Cognexa logo" className={classes.logo} />
      </Link>{' '}
    </Container>
  )
}

export default Footer
